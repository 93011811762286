import { useState } from "react";

// Windows-1254 karşılıkları
const windows1254Chars: Record<string, string> = {
  'ç': '#231',
  'ı': '#305',
  'ğ': '#287',
  'ş': '#351',
  'ö': '#246',
  'ü': '#252',
  'Ç': '#199',
  'İ': '#304',
  'Ğ': '#286',
  'Ş': '#350',
  'Ö': '#214',
  'Ü': '#220'
};

export default function HexConverter() {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");

  const convertToHex = (text: string) => {
    let hexOutput = "";
    let inQuotes = false; // Tırnak içinde olup olmadığımızı takip edeceğiz.

    // Her bir karakteri kontrol et
    for (let i = 0; i < text.length; i++) {
      let char = text[i];
      
      // Eğer Türkçe karakterse, Windows-1254 karşılıklarına çevir
      if (windows1254Chars[char]) {
        // Eğer karakter Türkçe ise, tırnak içine almak
        if (!inQuotes) {
          hexOutput += `'${windows1254Chars[char]}`; // Tırnağı aç
          inQuotes = true;
        } else {
          hexOutput += windows1254Chars[char]; // Tırnak içine ekle
        }
      } else {
        // Türkçe karakter değilse, normal olarak ekle
        if (inQuotes) {
          hexOutput += `'`; // Tırnağı kapat
          inQuotes = false;
        }
        hexOutput += char; // Normal karakteri olduğu gibi ekle
      }
    }

    // Eğer son bir Türkçe karakter kaldıysa, tırnağı kapat
    if (inQuotes) {
      hexOutput += `'`;
    }

    setOutputText(hexOutput); // Çıktıyı güncelle
  };

  return (
    <div className="hex-converter p-4 bg-white rounded-lg shadow-sm">
      <h2 className="text-xl font-semibold mb-4">Türkçe Karakterleri Windows-1254 Formatına Dönüştür</h2>
      <textarea
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Metninizi buraya yazın..."
        className="w-full p-2 border rounded-md"
      />
      <button
        onClick={() => convertToHex(inputText)}
        className="mt-4 px-6 py-2 bg-blue-500 text-white rounded-md"
      >
        Dönüştür
      </button>
      {outputText && (
        <div className="mt-4">
          <h3 className="font-medium">Çıktı:</h3>
          <pre className="bg-gray-100 p-4 rounded-md">{outputText}</pre>
        </div>
      )}
    </div>
  );
}
